import React, { useState, useEffect } from "react";
import loginlogo from '../images/login-logo.png';
import { Card, Modal, Row, Col } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import AdminMenu from "./AdminMenu";
import './Dashboard.css';
import { Bar } from 'react-chartjs-2';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AllArticles = () => {
    const [isOn, setIsOn] = useState(false);
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [showPopup, setShowPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([])
    const [allArticles, setAllArticles] = useState([]);
    const [articleLikes, setArticleLikes] = useState([]);
    const [articleViews, setArticleViews] = useState([]);
    const [name, setName] = useState('')

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    useEffect(() => {
        getAllArticles();
    }, [])

    const getAllArticles = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllArticlesForLast28Days', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllArticles(response.data.articles)
                    setArticleLikes(response.data.articleLikes)
                    setArticleViews(response.data.articleViews)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };


    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap-a" style={{ fontFamily: "Noto Sans" }}>
                <div className="your-jitbits">Articles</div>
                <div className="bg-merchandise" >
                    <div className="bg-eve adm-bg-height-eve">
                        {allArticles.length === 0 ? (
                            <div>No articles</div>
                        ) : (
                            allArticles.map((data, i) => {
                                const articleLikeData = articleLikes.find(like => like.id === data.id);
                                const likes = articleLikeData ? articleLikeData.likes : 0;
                                const articleViewData = articleViews.find(view => view.id === data.id);
                                const views = articleViewData ? articleViewData.views : 0;
                                return (
                                    <div className="art-card" key={data.id}>
                                        <strong>{data.title}</strong>
                                        <div>Publish On: {moment(data.publishOn).format("DD-MM-YYYY")}</div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>Likes: {likes}</div>
                                            <div>Views: {views}</div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <div className="mb-bottom"></div>
            <AdminMenu />
        </div>
    );
};

export default AllArticles;