import React, { useState, useEffect } from "react";
import loginlogo from '../images/login-logo.png';
import { Card, Modal, Row, Col } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import AdminMenu from "./AdminMenu";
import './Dashboard.css';
import { Bar } from 'react-chartjs-2';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AllPolls = () => {
    const [isOn, setIsOn] = useState(false);
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [showPopup, setShowPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([])
    const [allPolls, setAllPolls] = useState([]);
    const [articlePolls, setArticlePolls] = useState([]);
    const [name, setName] = useState('')

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    useEffect(() => {
        getAllPolls();
    }, [])

    const getAllPolls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllPollsForLast28Days', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllPolls(response.data.polls)
                    setArticlePolls(response.data.articlePolls)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };


    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap-a" style={{ fontFamily: "Noto Sans" }}>
                <div className="your-jitbits">Polls</div>
                <div className="bg-merchandise">
                    <div className="bg-eve adm-bg-height-eve">
                        {allPolls.length === 0 && articlePolls.length === 0 ? (
                            <div>No polls</div>
                        ) : (
                            <>
                                {/* Render allPolls if not empty */}
                                {allPolls.length > 0 && allPolls.map((data, i) => (
                                    <div className="art-card" key={i}>
                                        <strong>{data.pollQuestion}</strong>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>Start Date: {moment(data.startDate).format("DD-MM-YYYY")}</div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>Registered users: {data.peopleparticipated}</div>
                                            <div>Unregistered users: {data.peopleparticipatedasunregistered}</div>
                                        </div>
                                    </div>
                                ))}

                                {/* Render articlePolls if not empty */}
                                {articlePolls.length > 0 && articlePolls.map((data, i) => (
                                    <div className="art-card" key={i}>
                                        <strong>{data.pollQuestion}</strong>
                                        <div>Published Date: {moment(data.publishOn).format("DD-MM-YYYY")}</div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div>Registered users: {data.peopleParticipated}</div>
                                            <div>Unregistered users: {data.peopleParticipatedAsUnregistered}</div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="mb-bottom"></div>
            <AdminMenu />
        </div>
    );
};

export default AllPolls;