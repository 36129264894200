import React, { useState, useEffect } from "react";
import loginlogo from '../images/login-logo.png';
import { OverlayTrigger, Tooltip, Carousel, Row, Col, Button, Card, Popover, Form, Modal } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import AdminMenu from "./AdminMenu";
import './Dashboard.css';
import moment from 'moment-timezone';
import { Bar } from 'react-chartjs-2';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Dashboard = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [width, setWidth] = useState(0);
    const [pollWidth, setPollWidth] = useState(0);
    const [maxArticleCount, setMaxArticleCount] = useState(0);
    const [maxPollCount, setMaxPollCount] = useState(0);
    const [articles, setArticles] = useState();
    const [polls, setPolls] = useState()
    const [previousMonth, setPreviousMonth] = useState('');
    const [articleRegistered, setArticleRegistered] = useState('')
    const [articleUnRegistered, setArticleUnRegistered] = useState('')
    const [pollRegistered, setPollRegistered] = useState('')
    const [pollUnRegistered, setPollUnRegistered] = useState('')
    const [articlePollRegistered, setArticlePollRegistered] = useState('')
    const [articlePollUnRegistered, setArticlePollUnRegistered] = useState('')
    const [usersCount, setUsersCount] = useState('');
    const [newUsersCount, setNewUsersCount] = useState('');
    const [activeUsersCount, setActiveUsersCount] = useState('');
    const [activeUsers, setActiveUsers] = useState([]);
    const [inactiveUsersCount, setInActiveUsersCount] = useState('');
    const [registrationsCount, setRegistrationsCount] = useState('');
    const [merchandiseCount, setMerchandiseCount] = useState('');
    const [eventsCount, setEventsCount] = useState('');
    const [loading, setLoading] = useState(true);
    const [hoveredBarIndex, setHoveredBarIndex] = useState(null);
    const [usersData, setUsersData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Users',
                data: [],
                borderRadius: 5,
                borderWidth: 1,
                barThickness: 25,
                borderThickness: 10,
                scaleID: 'y',
            }
        ]
    });

    useEffect(() => {
        getActiveUserDetailsLast28Days();
        getInactiveUserDetails();
        getAdminDashboardCounts();
        const getPreviousMonth = () => {
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 month
            const month = currentDate.toLocaleString('default', { month: 'long' }); // Get full month name
            //  const year = currentDate.getFullYear(); // Get the year
            return `${month}`;
        };
        setPreviousMonth(getPreviousMonth());
    }, []);


    useEffect(() => {
        if (activeUsersCount !== null && inactiveUsersCount !== null && newUsersCount !== null) {
            setUsersCount(activeUsersCount + inactiveUsersCount + newUsersCount);
            console.log("u", activeUsersCount, inactiveUsersCount, newUsersCount)
        }
    }, [activeUsersCount, inactiveUsersCount, newUsersCount]);
    const getInactiveUserDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getInactiveUserDetails', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    setInActiveUsersCount(response.data.user.length)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getActiveUserDetailsLast28Days = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getActiveUserDetailsLast28Days', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    setActiveUsersCount(response.data.user.length)
                    setActiveUsers(response.data.user)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const getAdminDashboardCounts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAdminDashboardCounts', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setArticles(response.data.articles);
                    setPolls(parseInt(response.data.polls) + parseInt(response.data.articlePoll));
                    setEventsCount(response.data.eventsCount);
                    setMerchandiseCount(response.data.merchandiseCount);
                    setRegistrationsCount(response.data.registrationsCount);
                    setNewUsersCount(response.data.newUsersCount.length)
                    setArticleRegistered(response.data.articleRegistered);
                    setArticleUnRegistered(response.data.articleUnRegistered);
                    setPollRegistered(parseInt(response.data.pollRegistered == null ? 0 : response.data.pollRegistered) + parseInt(response.data.articlePollRegistered));
                    setPollUnRegistered(parseInt(response.data.pollUnRegistered == null ? 0 : response.data.pollUnRegistered) + parseInt(response.data.articlePollUnRegistered));
                    const pollRegistered = parseInt(response.data.pollRegistered == null ? 0 : response.data.pollRegistered) + parseInt(response.data.articlePollRegistered);
                    const pollUnRegistered = parseInt(response.data.pollUnRegistered == null ? 0 : response.data.pollUnRegistered) + parseInt(response.data.articlePollUnRegistered);

                    const maxPollCount = Math.max(pollRegistered, pollUnRegistered);
                    setMaxPollCount(maxPollCount)
                    const maxArticleCount = Math.max(parseInt(response.data.articleRegistered), parseInt(response.data.articleUnRegistered));
                    setMaxArticleCount(maxArticleCount)
                    // const article = parseInt(response.data.articleRegistered) + parseInt(response.data.articleUnRegistered);
                    // const articlePercent = (parseInt(response.data.articleRegistered) / article) * 100;
                    // setArticlePollRegistered(response.data.articlePollRegistered);
                    // setArticlePollUnRegistered(response.data.articlePollUnRegistered);
                    // const pollRegistered = parseInt(response.data.pollRegistered)+parseInt(response.data.articlePollRegistered);
                    // const pollUnRegistered = parseInt(response.data.pollUnRegistered)+parseInt(response.data.articlePollUnRegistered);
                    // const poll = pollRegistered + pollUnRegistered;
                    // let pollPercent = 0;
                    // if (poll > 0) {
                    //     pollPercent = (pollRegistered / poll) * 100;
                    // }
                    // const roundedPollPercent = Math.round(pollPercent);
                    // setPollWidth(roundedPollPercent !== 0 ? roundedPollPercent : 0);
                    // setWidth(articlePercent != 0 ? Math.round(articlePercent) : 0);
                }
            })
            .catch(function (error) {
                console.log("Error");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const getLast28DaysWeeks = () => {
        const currentDate = moment();
        const startDate = currentDate.clone().subtract(27, 'days'); // 28 days ago
        const weeks = [];
        let currentStartDate = startDate;
        for (let i = 0; i < 4; i++) {
            const currentEndDate = currentStartDate.clone().add(6, 'days');
            weeks.push({
                start: currentStartDate.format('YYYY-MM-DD'),
                end: currentEndDate.format('YYYY-MM-DD'),
                label: `Week ${i + 1}`
            });
            currentStartDate = currentEndDate.clone().add(1, 'day'); // Move to the next week's start
        }
        return weeks;
    };

    const getWeekOfMonth = (date, weeks) => {
        const momentDate = moment(date);
        // Check the user's date and return the corresponding week
        for (let week of weeks) {
            if (momentDate.isBetween(week.start, week.end, 'days', '[]')) {
                return week.label; // Week number as a label
            }
        }
        return null; // If no match
    };

    const countUsersByWeek = (activeUsers) => {
        const weeks = getLast28DaysWeeks();
        const weeklyCounts = [0, 0, 0, 0]; // Assuming 4 weeks

        // Count users for each week
        activeUsers.forEach(user => {
            const userWeek = getWeekOfMonth(user.updatedAt, weeks);
            const weekIndex = weeks.findIndex(w => w.label === userWeek);
            if (weekIndex !== -1) {
                weeklyCounts[weekIndex]++;
            }
        });
        console.log("week", weeklyCounts);
        const updatedData = {
            labels: weeks.map(week => week.label), // Labels: Week 1, Week 2, etc.
            datasets: [
                {
                    label: 'Users',
                    data: weeklyCounts,
                },
            ],
        };
        return updatedData;
    };
    useEffect(() => {
        if (activeUsers.length > 0) {
            const updatedData = countUsersByWeek(activeUsers);
            setUsersData(updatedData);
        }
    }, [activeUsers]);
    const maxBarHeight = 120;  // The maximum height of the bars in the circle container (adjust if needed)
    const maxCount = Math.max(...usersData.datasets[0].data);
    return (
        <div>
            {loading ? (
                <div>
                    <div className="top_bar">
                        <img src={loginlogo} className="login-logo" />
                    </div>
                    <div className="spinnerloading">
                    </div>
                    <div className="mb-bottom"></div>
                    <AdminMenu />
                </div>
            ) : (
                <div>
                    <div className="top_bar">
                        <img src={loginlogo} className="login-logo" />
                    </div>
                    <div className="screen-topgap-a" style={{ fontFamily: "Noto Sans" }}>
                        <div className="your-jitbits">Dashboard</div>
                        <div style={{ marginTop: "-8px", background: "#F1F4F8", padding: "5px", borderRadius: "10px" }}>
                            <strong className="ml-3" style={{ fontSize: "14px" }}>Last 28 days</strong>
                            <center>
                                {/* <div className="circle-container" style={{ marginTop: "-10px" }}>
                                    <div style={{ transform: "rotate(45deg)", width: "80%" }}> */}
                                {/* <Bar
                                            data={usersData}
                                            options={{
                                                responsive: true,
                                                elements: {
                                                    bar: {
                                                        backgroundColor:'white',
                                                        shadowOffsetX: 10,
                                                        shadowOffsetY: 15, 
                                                        shadowBlur: 20,
                                                        shadowColor: '#E8E8E8', 
                                                    },
                                                },
                                                scales: {
                                                    indexAxis: 'y', // Horizontal bar chart
                                                    x: {
                                                        grid: {
                                                            display: false,
                                                            drawBorder: false,
                                                        },
                                                        ticks: {
                                                            display: false,
                                                        },
                                                    },
                                                    y: {
                                                        grid: {
                                                            display: false,
                                                            drawBorder: false,
                                                        },
                                                        ticks: {
                                                            display: false,
                                                        },
                                                        beginAtZero: true,
                                                    },
                                                },
                                                plugins: {
                                                    title: {
                                                        display: false,
                                                        text: "Users",
                                                    },
                                                    legend: {
                                                        display: false,
                                                    },
                                                },
                                            }}
                                        /> */}
                                <div className="circle-container" style={{ marginTop: "-10px" }}>
                                    <div style={{ transform: "rotate(45deg)", width: "80%" }}>
                                        <div className="bar-chart-container">
                                            <div className="bar-chart">
                                                {usersData.datasets[0].data.map((count, index) => {
                                                    const barHeight = Math.min((count / maxCount) * maxBarHeight, maxBarHeight);

                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement="right"
                                                            overlay={
                                                                <Popover id={`popover-${index}`} className="bar-popover">
                                                                    <Popover.Content>
                                                                        <strong>{usersData.labels[index]}</strong><br />
                                                                        <strong>Count: {count}</strong>
                                                                    </Popover.Content>
                                                                </Popover>
                                                            }
                                                        >
                                                            <div
                                                                className="bars"
                                                                style={{
                                                                    height: `${barHeight}px`,
                                                                    boxShadow: '-2px -2px 6px rgba(190, 190, 190, 0.5), 2px 2px 6px rgba(255, 255, 255, 0.6)', // Box shadow for bars
                                                                }}
                                                            >
                                                            </div>
                                                        </OverlayTrigger>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-5.5vh" }}>
                                    <strong style={{ fontSize: "14px" }}>Total Users: {usersCount}</strong>
                                </div><br />
                                <strong style={{ fontSize: "14px" }}>User Activity</strong>
                            </center>
                            {/* <div>
                                <strong className="ml-3" style={{ fontSize: "14px" }}> Article Reads</strong>:
                                <span style={{ color: "gray", fontSize: "12px" }}> Registered vs Unregistered</span>
                                <div className="purple-bar-container">
                                    <div className="in-purple-bar"></div>
                                    <div className="purple-bar" style={{ width: `${width}%` }} >
                                        <span className="text-purple">{width}%</span>
                                    </div>
                                </div>
                                <br />
                                <strong className="ml-3" style={{ fontSize: "14px" }}>Poll Answers</strong>:
                                <span style={{ color: "gray", fontSize: "12px" }}> Registered vs Unregistered</span>
                                <div className="mb-3 cream-bar-container">
                                    <div className="in-cream-bar"></div>
                                    <div className="cream-bar" style={{ width: `${pollWidth}%` }} >
                                        <span className="text-purple">{pollWidth}%</span>
                                    </div>
                                </div>
                            </div> */}
                            <div className="">
                                <div className="row no-gutters">
                                    <Col xs={3}>
                                        <center style={{ color: "black" }}>
                                            <strong style={{ fontSize: "14px" }}>Articles</strong>
                                            <Link to="/allarticles">
                                                <div className="ap-box" style={{color:"black", background: "#D9D1F7" }}>
                                                    {articles}
                                                </div></Link>
                                        </center>
                                    </Col>
                                    <Col xs={9}>
                                        <div className="count-top">
                                            <div className="p-bar-r" style={{ width: `${articleRegistered ? (articleRegistered / maxArticleCount) * 107 : 0}%` }}>
                                                <div className="text-b-dashboard">{articleRegistered}</div>
                                            </div>
                                            <div className="p-bar-u" style={{ width: `${articleUnRegistered ? (articleUnRegistered / maxArticleCount) * 107 : 0}%` }}>
                                                <div className="text-b-dashboard">{articleUnRegistered}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <div className="mt-3 row no-gutters">
                                    <Col xs={3}>
                                        <center style={{ color: "black" }}>
                                            <strong style={{ fontSize: "14px" }}>Polls</strong>
                                            <Link to="/allpolls">
                                                <div className="ap-box" style={{ color:"black",background: "#FFE9A3" }}>
                                                    {polls}
                                                </div>
                                            </Link>
                                        </center>
                                    </Col>
                                    <Col xs={9}>
                                        <div className="mb-2 count-top">
                                            <div className="c-bar-r" style={{ width: `${pollRegistered ? (pollRegistered / maxPollCount) * 107 : 0}%` }}>
                                                <div className="text-b-dashboard">{pollRegistered}</div>
                                            </div>
                                            <div className="c-bar-u" style={{ width: `${pollUnRegistered ? (pollUnRegistered / maxPollCount) * 107 : 0}%` }}>
                                                <div className="text-b-dashboard">{pollUnRegistered}</div>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                        <center className="mt-4">
                            <div style={{ background: "#F1F4F8", padding: "2px 3px 14px 3px", borderRadius: "10px" }}>
                                <Row style={{ width: "100%" }}>
                                    <Col>
                                        <Link to="/registrations" style={{ color: "black" }}>
                                            <strong style={{ fontSize: "14px" }}>Registrations</strong>
                                            <div className="r-box">
                                                {registrationsCount}
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link to="/merchandise" style={{ color: "black" }}>
                                            <strong style={{ fontSize: "14px" }}>Merchandise</strong>
                                            <div className="m-box">
                                                {merchandiseCount}
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Link to="/allevents" style={{ color: "black" }}>
                                            <strong style={{ fontSize: "14px" }}>Events</strong>
                                            <div className="e-box">
                                                {eventsCount}
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        </center>
                    </div>
                    <div className="mb-bottom"></div>
                    <AdminMenu />
                </div>
            )}
        </div>
    );
};

export default Dashboard;
