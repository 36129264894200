import React, { Component } from "react";
import { Button, Nav, Form } from "react-bootstrap";
import { Link, NavLink, Redirect } from 'react-router-dom';
import homeicon from '../images/homeicon.png';
import articleicon from '../images/articleicon.png';
import bookmarks from '../images/bookmarkicon.png';
import menuicon from '../images/menuicon.png';
import usericon from '../images/user.png';
import searchicon from '../images/search.png';
import scm from '../images/Scm networking.png';
import poll from '../images/Poll.png';
import pricing from '../images/pricing.png';
import membership from '../images/membership.svg';
import advertisementmenu from '../images/advertisementmenu.svg';
import registersme from '../images/register SME.svg';
import appointments from '../images/appointments.svg';
import referral from '../images/referral.svg';
import preference from '../images/Preference.png';
import settings from '../images/settings.png';
import logout from '../images/logout.png';
import logout1 from '../images/logout1.png';
import downarrow from '../images/downarrow.png';
import rightarrow from '../images/rightarrow.png';
import line from '../images/line.png';
import qna from '../images/QnA.svg';
import sceemlore from '../images/sceemlore-logo.svg';
import opportunity from '../images/opportunity.png';
import newmembers from '../images/new member.png';
import line1 from '../images/line2.png';
import line2 from '../images/line1.png';
import bug from '../images/bug.svg';
import '../BottomMenu/BottomMenu.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
export default class AdminMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navwidth: 0,
            user: false,
            qa: false,
            showSceemSme: false,
            group: false,
            opportunity: false,
            advertisement: false,
            incident: false,
            referral: false,
            profile: false,
            toggleUser: false,
            scm: false,
            showModal: false,
            password: false,
            oldPassword: "",
            newPassword: "",
            log: false,
            redirectToLogin: false,
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            email: localStorage.getItem('email'),
            role: localStorage.getItem('role'),
            userRole: "",
            profilePicture: "",
            name: "",
            ageGroup: "",
            company: "",
            experience: "",
            city: "",
            gender: "",
            author: "",
            features: [],
            planType: "",
            membership: localStorage.getItem("planType"),
            reviewer: "",
            sme: "",
            disable: false,
            oldPasswordError: "",
            newPasswordError: "",
            userStatus: localStorage.getItem('userStatus'),
        }
        this.showAdd = this.showAdd.bind(this);
        this.showLogout = this.showLogout.bind(this);
        this.logoutFromApp = this.logoutFromApp.bind(this);
        this.postExpressInterest = this.postExpressInterest.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateOldPassword = this.validateOldPassword.bind(this);
        this.validateNewPassword = this.validateNewPassword.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    componentDidMount = () => {
        this.checkUserStatus()
        this.getMenuItems()
    }
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
        let formFileds = [
            "oldPassword",
            "newPassword"

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
            // console.log("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid details</div>"
        } else {
            this.changePassword()
        }
    }
    validateField(name) {
        let isValid = false;
        if (name === "oldPassword") isValid = this.validateOldPassword();
        else if (name === "newPassword") isValid = this.validateNewPassword();
        return isValid;
    }
    validateOldPassword() {
        let oldPasswordError = "";
        const value = this.state.oldPassword;
        if (value.trim === "") oldPasswordError = "Old Password is required";
        else if (!validPasswordRegex.test(value))
            oldPasswordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            oldPasswordError
        });
        return oldPasswordError === "";
    }

    validateNewPassword() {
        let newPasswordError = "";
        const value = this.state.newPassword;
        if (value.trim === "") newPasswordError = "New Password is required";
        else if (!validPasswordRegex.test(value))
            newPasswordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            newPasswordError
        });
        return newPasswordError === "";
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    // openNav = () => {
    //     document.getElementById("mySidenav").style.width = "330px";
    // }
    // closeNav = () => {
    //     document.getElementById("mySidenav").style.width = "0";
    // }
    openNav = () => {
        this.setState({
            navwidth: '330px'
        });
        this.getUserProfile()
    }

    closeNav = () => {
        this.setState({
            navwidth: 0
        });
    }
    closeMenu = (e) => {
        if (e.target.tagName === "div") {
            document.getElementById("mySidenav").style.width = "0";
        }
    }
    showUser = () => {
        this.setState({ user: !this.state.user })
    }
    showQA = () => {
        this.setState({ qa: !this.state.qa })
    }
    showSME = () => {
        this.setState({ showSceemSme: !this.state.showSceemSme })
    }
    showOpportunity = () => {
        this.setState({ opportunity: !this.state.opportunity })
    }
    showAdvertisement = () => {
        this.setState({ advertisement: !this.state.advertisement })
    }
    showReferral = () => {
        this.setState({ referral: !this.state.referral })
    }
    showGroup = () => {
        this.setState({ group: !this.state.group })
    }
    showProfile = () => {
        this.setState({ profile: !this.state.profile })
    }
    showIncident = () => {
        this.setState({ incident: !this.state.incident })
    }
    showToggleUser = () => {
        this.setState({ toggleUser: !this.state.toggleUser })
    }
    showScm = () => {
        this.setState({ scm: !this.state.scm })
    }
    showPassword = () => {
        this.setState({ password: !this.state.password })
    }
    showAdd = () => {
        document.getElementById('myBtn').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('myBtn').style.display = 'none'
    }
    addSubscribtion = () => {
        document.getElementById('subscribe').style.display = 'flex'
    }
    showNothing = () => {
        document.getElementById('subscribe').style.display = 'none'
    }
    showLogout = () => {
        document.getElementById('logout').style.display = 'flex'
    }
    showLogoutNone = () => {
        document.getElementById('logout').style.display = 'none'
    }
    close = () => {
        document.getElementById(".Modal").style.display = "none"
    }
    // optForReviewer = () => {
    //     document.getElementById('reviewer').style.display = 'flex'
    // }
    // showReviewerExpressInterestNone = () => {
    //     document.getElementById('reviewer').style.display = 'none'
    // }
    // optForAuthor = () => {
    //     document.getElementById('author').style.display = 'flex'
    // }
    // showAuthorExpressInterestNone = () => {
    //     document.getElementById('author').style.display = 'none'
    // }
    getMenuItems = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getMenuItems', { headers: headers })
            .then(function (response) {
                //console.log("menu", response);
                if (response.data.status === true) {
                    self.setState({
                        features: response.data.menuItems[0].features,
                        planType: response.data.menuItems[0].planId
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    postExpressInterest = (e, type) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "interestType": type
        }
        var self = this
        axios.post(config.userUrl + 'user/expressInterest', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.checkUserStatus()
                    self.showReviewerExpressInterestNone()
                    self.showAuthorExpressInterestNone()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    checkUserStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "approvedForAuthor") {
                        self.setState({ author: "Approved", disable: true })
                        //  document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForReviewer") {
                        self.setState({ reviewer: "Approved", disable: true })
                        //  document.getElementById('false').innerHTML = '<i class="fa fa-check" id="false" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForSme") {
                        self.setState({ sme: "Approved", disable: true })
                        //  document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "authorStatusPending") {
                        self.setState({ author: "Pending", disable: true })
                        // document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "reviewerStatusPending") {
                        self.setState({ reviewer: "Pending", disable: true })
                        //  document.getElementById('pendingReviewer').innerHTML = '<i class="fa fa-check" id="pendingReviewer" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "smeStatusPending") {
                        self.setState({ sme: "Pending", disable: true })
                        //  document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else {
                        document.getElementById('status').innerHTML = '<div>{response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}</div>'
                    }
                } else {
                    // console.log("checkerror", response.data.message)
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProfile', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    let user = response.data.user;
                    self.setState(
                        {
                            name: user.name,
                            ageGroup: user.ageGroup,
                            experience: user.experience,
                            company: user.company,
                            city: user.city,
                            gender: user.gender
                        }
                    );
                    if ((user.profilePicture === null) && (user.gender === "0")) {
                        self.setState({
                            profilePicture: "uploads/1652942537335Avatar%20Users2_37.png",
                        })
                    } else if ((user.profilePicture === null) && (user.gender === "1")) {
                        self.setState({
                            profilePicture: "uploads/1652942450250Avatar Users2_31.png",
                        })
                    } else {
                        self.setState({
                            profilePicture: user.profilePicture,
                        })
                    }
                    if (self.state.role === "generalUser") {
                        // console.log("general user")
                        self.setState({ userRole: "User" })
                    } else {
                        //  console.log("author user")
                        const data = self.state.role.charAt(0).toUpperCase() + self.state.role.slice(1)
                        self.setState({ userRole: data })
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    componentDidUpdate() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    }
    logoutLocal = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.apiUrl + 'auth/logout', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    localStorage.clear();
                    self.clearAllCookies();
                    self.setState(
                        {
                            log: true
                        })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    clearAllCookies = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
    }
    logoutFromApp = () => {
        localStorage.clear();
        this.setState({
            log: true
        })
    }
    changePassword = (e) => {
        e.preventDefault();
        var encryptedOldPassword = simpleCrypto.encrypt(this.state.oldPassword);
        var encryptedNewPassword = simpleCrypto.encrypt(this.state.newPassword);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "oldPassword": encryptedOldPassword,
            "newPassword": encryptedNewPassword
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/userChangePassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    //console.log("Password changed successfully!!")
                    localStorage.clear()
                    self.setState({
                        redirectToLogin: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { log, redirectToLogin, features } = this.state;
        if (redirectToLogin) {
            return <Redirect to="/" />
        }
        if (log) {
            return <Redirect to="/" />
        }
        var feature = features.map(data => data.feature)
        return (
            <div>
                <nav className="bottom_bar">
                    <Nav className="w-100">
                        <div className=" d-flex flex-row justify-content-around w-100">
                            <NavLink to={"/admindashboard"} className="nav-link element" activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={homeicon} className="menu-Icon" />
                                </div>
                            </NavLink>
                            <NavLink to={"/jitbits"}  className={true ? "nav-link element disabled-link" : "nav-link element"} activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={articleicon} className="menu-Icon" />
                                </div>
                            </NavLink>
                            <NavLink to={"/smeconnect"}  className={true ? "nav-link element disabled-link" : "nav-link element"} activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={appointments} className="menu-Icon1" />
                                </div>
                            </NavLink>
                            <NavLink to={"/authors/recommended"}  className={true ? "nav-link element disabled-link" : "nav-link element"} activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={scm} className="menu-Icon" />
                                </div>
                            </NavLink>
                            <a className="nav-link element" onClick={this.openNav}>
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={menuicon} className="menu-Icon" />
                                </div>
                            </a>
                        </div>
                    </Nav>
                </nav>

                <div onBlur={this.closeMenu}>
                    <div id="mySidenav" class="sidenav" style={{ width: this.state.navwidth }}>
                        <a class="closebtn" onClick={this.closeNav}>&times;</a>
                        <center>
                            <img alt="" src={config.userUrl + this.state.profilePicture} className="user-img" />
                            <div className="user-name">{this.state.name}</div>
                            <div className="user-id">({this.state.userRole}, {localStorage.getItem("planType") == 1 ? "Anticipator" : (localStorage.getItem("planType") == 2 ? "Integrator" : (localStorage.getItem("planType") == 3 ? "Collaborator" : "Orchestrator"))})</div></center>

                        <div>  <a href="#" className="sidenav-text" onClick={this.showLogout}><img alt="" src={logout} className="logouticon" />&nbsp; &nbsp; Logout</a>
                            <div id="logout" className="modal-pop">
                                <form className="modal-content-log">
                                    <div className="_container">
                                        <img alt="" src={logout1} className="logouticon1" />
                                        <p className="mt-2">Do you really want to logout from your account?</p>
                                        <center>                            <Button variant="default" onClick={this.logoutLocal} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                            <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                {/* )} */}
            </div >
        )
    }
}


