import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row, Modal } from "react-bootstrap";
import { useLocation, useNavigate, Redirect } from 'react-router-dom';
import BottomMenu from "../BottomMenu/BottomMenu";


export default function UpgradePopup() {
    const [showUpgradePopup, setShowUpgradePopup] = useState(false)
    if (showUpgradePopup) {
        return <Redirect
            to={{
                pathname: "/subscription",
                state: { pName: localStorage.getItem('planType') == 1 ? "Anticipator" : "" }
            }} />
    }
    return (
        <div>
            <Modal show={true} size="md"
                className="custom-modal" >
                <Modal.Body>
                    <center>
                        <p>You need to upgrade membership to read the full content.</p>
                        <Button variant="default" className="up-p" onClick={() => setShowUpgradePopup(true)}>Upgrade Membership</Button>
                    </center>
                </Modal.Body>
            </Modal>
        </div>
    )
}
