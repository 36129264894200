import React, { useState, useEffect } from "react";
import BottomMenu from "../BottomMenu/BottomMenu";
import loginlogo from '../images/login-logo.png';
import profilePicture from '../images/author1.svg'
import { Card, Modal, Row, Col } from "react-bootstrap";
import moment from 'moment-timezone';
import googlelinkImg from '../images/googlelink.svg';
import linktext from '../images/linktext.svg';
import nodata from '../images/no-data.png';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import 'react-datepicker/dist/react-datepicker.css';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ViewSmeEvents = (props) => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [showPopup, setShowPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([])
    const [eventsData, setEventsData] = useState([]);
    const [name, setName] = useState('')
    const [showProfile, setShowProfile] = useState(false)
    const months = [
        "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ];
    const history = useHistory();
    const currentMonthIndex = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    // const [eventsData, setEventsData] = useState([
    //     { id: 1, date: "2024-09-01", type: "Network", desc: "Event 1 Description", time: "4:30 PM", name: "Srigowri", link: "https://www.google.com", "registered": 3 },
    //     { id: 2, date: "2024-09-15", type: "Coach", desc: "Event 2 Description", time: "10:30 AM", name: "Srigowri", link: "https://www.google.com", "registered": 5 },
    //     { id: 3, date: "2024-10-05", type: "Advisory", desc: "Event 3 Description", time: "11:30 AM", name: "Srigowri", link: "https://www.google.com", "registered": 0 }
    //     // Add more events as needed
    // ]);

    useEffect(() => {
        getAllSmeOfferings();
        getAllDays();
        getAllCommercialPlans();
        getSmeCreatedEventsDataByUser(currentMonthIndex);

    }, [])
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    setAllDays(response.data.days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setCommercialPlans(response.data.commercialPlans)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getSmeCreatedEventsDataByUser = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "month": id,
            "smeUserId": props.match.params.id
        }
        axios.post(config.userUrl + 'user/getSmeCreatedEventsDataByUser', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setEventsData(response.data.eventsData)
                    setName(response.data.eventsData[0]?.name)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // const changeEventStatusBySme = (id) => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "status": 2,
    //         "id":id
    //     }
    //     axios.post(config.userUrl + 'user/changeEventStatusBySme', payload, { headers: headers })
    //         .then(function (response) {
    //             // console.log(response);
    //             if (response.data.status === true) {
    //                 getSmeCreatedEventsData(currentMonthIndex)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    const getText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    }
    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value, 10));
        getSmeCreatedEventsDataByUser(parseInt(e.target.value, 10));

    };

    const handleDeleteEvent = (id) => {
        setEventsData(eventsData.filter(event => event.id !== id));
    };
    const handlePopup = (data) => {
        setShowPopup(true)
        setPopupData(data)
    }
    const hasEvents = (monthIndex, year) => {
        return eventsData.some(event => event.month === monthIndex && event.year === year);
    };
    const openLinkUrl = (link) => {
        window.open(`${link}`)
    }
    const goBack = () => {
        history.goBack()
    }
    // if (showProfile) {
    //     return <Redirect to={{
    //         pathname: "/smeprofile",
    //         state: { userId: userId, selectedTopic: selectedTopic, smeAmount: smeAmount, selectedPlanId: selectedPlanId, id: selectedOfferingId, sDate: selectedDate ? selectedDate : moment(location?.state?.selectedDate).format("DD"), sMonth: selectedMonth ? selectedMonth : moment(location?.state?.selectedDate).format("MM"), day: parseInt(day), userDate: new Date(userDate) ? new Date(userDate) : location?.state?.selectedDate, userTimeSlot: selectedTimeSlot }
    //     }} />
    // }
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">

                {/* <div className="your-jitbits">View {name} (SME) Events</div> */}
                {/* <Link to={`/profilesme/${props.match.params.id}`}> */}
                {/* <div onClick={() => setShowProfile(true)}>
                    <img alt="" src={back} className="back_arrow_" /></div>
                <div className="your-fav ml-5"> <div className="screen-topgap ml-4">View {name} (SME) Events</div></div> */}

                {/* <img
                    alt="Back"
                    src={back}
                    className="back_arrow_"
                    onClick={() => goBack()}
                    style={{ cursor: 'pointer' }}
                /><div className="your-fav ml-5" style={{ position: "fixed", top: "3.4vh" }}> <div className="screen-topgap ml-4">View SME Events</div></div>
                <br /> */}
                <div className="your-jitbits">View {name} (SME) Events</div>
                <div className="mt-2 bg-merchandise" >
                    <div className="bg-eve adm-bg-height-eve">

                        <div style={{ marginTop: "10px" }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column', // Stack the rows vertically
                                gap: '8px',
                                alignItems: 'center', // Centers the rows
                                width: '100%',
                            }}>
                                {/* Row 1: Jan - Jun */}
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '7px',
                                    justifyContent: 'center', // Centers the items inside the container
                                    alignItems: 'center', // Centers the items vertically
                                    width: '100%',
                                }}>
                                    {months.slice(0, 6).map((month, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                border: hasEvents(index, currentYear) ? '1px solid lightgreen' : '1px solid lightgray',
                                                backgroundColor: selectedMonth === index ? 'transparent' : '#f0f0f0',
                                                color: '#000',
                                                cursor: 'pointer',
                                                boxShadow: hasEvents(index, currentYear) ? '2px 3px 7px rgba(0, 0, 0, 0.3)' : '0 2px 2px rgba(0, 0, 0, 0.2)',
                                                transition: 'all 0.3s ease',
                                                fontSize: "14px",
                                                position: 'relative',
                                                textAlign: 'center',
                                            }}
                                            onClick={() => setSelectedMonth(index)}
                                        >
                                            {selectedMonth === index ? (
                                                <>
                                                    <div
                                                        style={{
                                                            width: '42px',
                                                            height: '42px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'transparent',
                                                            marginTop: '0.02rem',
                                                            boxShadow: 'inset 0 0 7px rgba(0, 0, 0, 0.3)',
                                                            position: 'absolute',
                                                        }}
                                                    ></div>
                                                    <span
                                                        style={{
                                                            position: 'relative',
                                                            zIndex: 1,
                                                        }}
                                                    >
                                                        {months[index].slice(0, 3)}
                                                    </span>
                                                </>
                                            ) : (
                                                months[index].slice(0, 3)
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* Row 2: Jul - Dec */}
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '7px',
                                    justifyContent: 'center', // Centers the items inside the container
                                    alignItems: 'center', // Centers the items vertically
                                    width: '100%',
                                }}>
                                    {months.slice(6).map((month, index) => (
                                        <div
                                            key={index + 6} // Adjusted index to account for the second row
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%',
                                                border: hasEvents(index + 6, currentYear) ? '1px solid lightgreen, 1px solid gray' : '1px solid lightgray',
                                                backgroundColor: selectedMonth === index + 6 ? 'transparent' : '#f0f0f0',
                                                color: '#000',
                                                cursor: 'pointer',
                                                boxShadow: hasEvents(index + 6, currentYear) ? '2px 3px 7px rgba(0, 0, 0, 0.3)' : '0 2px 2px rgba(0, 0, 0, 0.2)',
                                                transition: 'all 0.3s ease',
                                                fontSize: "14px",
                                                position: 'relative',
                                                textAlign: 'center',
                                            }}
                                            onClick={() => setSelectedMonth(index + 6)}
                                        >
                                            {selectedMonth === index + 6 ? (
                                                <>
                                                    <div
                                                        style={{
                                                            width: '42px',
                                                            height: '42px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'transparent',
                                                            marginTop: '0.02rem',
                                                            boxShadow: 'inset 0 0 7px rgba(0, 0, 0, 0.3)',
                                                            position: 'absolute',
                                                        }}
                                                    ></div>
                                                    <span
                                                        style={{
                                                            position: 'relative',
                                                            zIndex: 1,
                                                        }}
                                                    >
                                                        {months[index + 6].slice(0, 3)}
                                                    </span>
                                                </>
                                            ) : (
                                                months[index + 6].slice(0, 3)
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* Display events for the selected month */}
                        {eventsData.filter(event => {
                            const eventDate = new Date(event.bookingDate);
                            const eventMonth = eventDate.getMonth();
                            const eventYear = eventDate.getFullYear();
                            return eventMonth === selectedMonth && eventYear === currentYear;
                        }).length === 0 ? (
                            <div>
                                <img src={nodata} style={{ marginTop: "14%", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                                <center style={{ fontSize: "20px", marginTop: "1rem" }}>No events</center>
                            </div>
                        ) : (
                            eventsData.map((event, index) => {
                                const eventDate = new Date(event.bookingDate);
                                const eventMonth = eventDate.getMonth();
                                const eventYear = eventDate.getFullYear();

                                if (eventMonth === selectedMonth && eventYear === currentYear) {
                                    return (
                                        <div key={event.id} className="ml-2 mr-2 link"><br />
                                            <Card style={{ padding: "2px 0px 2px 5px" }} className="view_eve_card" >
                                                <Row style={{ width: "100%" }} className="no-gutters" onClick={() => handlePopup(event)}>
                                                    <Col xs={2}>
                                                        <img src={"../" + getText(event.offeringId).replace(/ *\([^]*\) */g, "") + ".svg"} alt={getText(event.offeringId)} style={{ width: "45px" }} />
                                                    </Col>
                                                    <Col xs={10}>
                                                        <div style={{ display: "flex", justifyContent: 'space-between', marginTop: "-3px" }}>
                                                            <strong>{moment(event.bookingDate).format("DD-MMM-YYYY")} @{event.time}</strong>
                                                        </div>
                                                        <div style={{ textAlign: "right", marginRight: "8px", fontSize: "14px", color: "gray" }}>
                                                            <span>{event.registeredcount == 0 ? "No participants" : <span>{event.registeredcount} participant(s) registered</span>}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })
                        )}
                        <Modal show={showPopup} size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            style={{ margin: 0 }}
                            centered>
                            <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px", padding: 0 }}>
                                <div className="sme-booking-slot-popup-planned">
                                    <div className="ml-1"> </div>
                                    <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>{getText(popupData.offeringId)} Event</div>
                                    <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                                </div> <Row style={{ width: "100%", marginTop: "2rem", height: "6.2rem" }} className="no-gutters">
                                    <Col xs={4}>
                                        <center><img alt="" src={config.userUrl + popupData.profilePicture} style={{ width: "58px", marginBottom: "5px" }} /></center>
                                        <center style={{ fontSize: "15px", lineHeight: "18px", fontFamily: "Noto Sans", fontWeight: 600 }} className="mt-2">{popupData?.name}</center>
                                    </Col>
                                    <Col xs={4}>
                                        <center>
                                            <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>Participants</div>
                                            <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>Registered</div>
                                            <div className="mt-3" style={{ lineHeight: "18px", fontSize: "25px", fontFamily: "Noto Sans", fontWeight: 600 }}>{popupData?.registeredcount}</div>
                                        </center>
                                    </Col>
                                    <Col xs={4}>
                                        <Card style={{ border: "none", marginRight: "15px", background: "#DCEAF7", borderRadius: "10px", fontWeight: 700, fontFamily: "Noto Sans" }}>
                                            <center>
                                                <div style={{ fontSize: "28px" }}> {moment(popupData.bookingDate).format("DD")}</div>
                                                <div style={{ fontSize: "16px", marginTop: "-4px" }}> {moment(popupData.bookingDate).format("MMM")}</div>
                                                <div style={{ fontSize: "16px", marginTop: "0px" }} className="mb-2"> {popupData.time}</div>
                                            </center>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className="mb-1"></div>
                                <div className="sme-booking-slot-popup-bottom">
                                    <div style={{ fontSize: "12px", padding: "3px 8px", cursor: "pointer" }} ></div>
                                    <div style={{ fontSize: "12px", padding: "3px 8px" }}><img src={linktext} /><i>{popupData.agenda}</i>
                                    </div>
                                    <div style={{ paddingBottom: "3px" }}></div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <style jsx>{`
            .events-container {
              margin-top: 10px;
              font-family:Noto Sans;
            }
            .event-box {
              background-color: #f9f9f9;
              border-radius: 8px;
              padding: 20px;
              margin-bottom: 15px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }
          `}</style>
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div>
    );
};

export default ViewSmeEvents;

