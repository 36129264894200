import React, { useState, useEffect } from "react";
import loginlogo from '../images/login-logo.png';
import { Card, Modal, Row, Col } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import AdminMenu from "./AdminMenu";
import './Dashboard.css';
import { enqueueSnackbar } from "notistack";
import moment from 'moment-timezone';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Registrations = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')));
    const [isOn, setIsOn] = useState(true);
    const [activeUsers, setActiveUsers] = useState([]);
    const [inactiveUsers, setInActiveUsers] = useState([]);
    const [roles, setRoles] = useState([
        { id: 1, userRole: "Admin" },
        { id: 2, userRole: "General User" },
        { id: 3, userRole: "Author" },
        { id: 4, userRole: "Reviewer" },
        { id: 5, userRole: "SME" }
    ])

    const toggleSwitch = () => {
        setIsOn(!isOn);
        if (isOn) {
            setActiveUsers(activeUsers);
        } else {
            setInActiveUsers(inactiveUsers);
        }
    };
    const getRole = (id) => {
        const item = roles.find(r => r.id === id);
        return item ? item.userRole : "";
    }
    useEffect(() => {
        getInactiveUserDetails();
        getActiveUserDetailsLast28Days();
    }, []);
    const getInactiveUserDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getInactiveUserDetails', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    setInActiveUsers(response.data.user)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getActiveUserDetailsLast28Days = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getActiveUserDetailsLast28Days', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    setActiveUsers(response.data.user)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const sendParticipationEmail = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "email": data.email,
            "name": data.name,
            "createdAt": data.createdAt
        }
        axios.post(config.userUrl + `user/sendParticipationEmail`, payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status == true) {
                    enqueueSnackbar("Email sent successfully", { variant: 'success' });
                    getInactiveUserDetails();
                    // document.getElementById('sentEmail').style.display = 'flex'
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap-a" style={{ fontFamily: "Noto Sans" }}>
                <div className="your-jitbits">Registrations</div>
                <div className="bg-merchandise">
                    <div className="bg-white">
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "22%", fontSize: "14px" }} className="ml-3">{isOn ? "Active" : "Inactive"}</div>
                            <div className={`toggle-container-admin ${isOn ? 'toggle-on-adm' : 'toggle-off-adm'}`} onClick={toggleSwitch}>
                                <div className="toggle-circle-admin"></div>
                            </div>
                        </div>
                        <div className="adm-bg-height">
                            {isOn ?
                                <div>
                                    {activeUsers.map((data, i) => (
                                        <div className="ml-2 mr-2 adm-order-box" key={i} >
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={6}>
                                                    <strong>{data.name}</strong>
                                                    <div>{data.email}</div>
                                                </Col>
                                                <Col xs={3}>
                                                    <div className="mt-2">{getRole(data.userRoleId)}</div>
                                                </Col>
                                                <Col xs={3}>
                                                    <div className="mt-2">{moment(data.updatedAt).format("DD-MM-YYYY")}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div> :
                                <div>
                                    {inactiveUsers.map((data, i) => (
                                        <div className="ml-2 mr-2 adm-order-box" key={i} >
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={7}>
                                                    <strong>{data.name}</strong>
                                                    <div>{data.email}</div>
                                                </Col>
                                                <Col xs={3}>
                                                    <div className="mt-2">{getRole(data.userRoleId)}</div>
                                                </Col>
                                                <Col xs={1}>
                                                    <div className="mt-2">&nbsp;&nbsp;{data.emailParticipationCount}</div>
                                                </Col>
                                                <Col xs={1}>
                                                    <div className="mt-2 cursor mr-1" style={{ textAlign: "right" }}>&nbsp;{data.emailParticipationCount === 5 ? <div></div> : <i onClick={() => sendParticipationEmail(data)} class="fa fa-envelope-o" aria-hidden="true"></i>}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-bottom"></div>
            <AdminMenu />
        </div>
    );
};

export default Registrations;