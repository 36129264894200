import React, { useState, useEffect } from "react";
import loginlogo from '../images/login-logo.png';
import { Card, Modal, Row, Col } from "react-bootstrap";
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import AdminMenu from "./AdminMenu";
import './Dashboard.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
var simpleCrypto = new SimpleCrypto(key);


const Merchandise = () => {
    const [isOn, setIsOn] = useState(false);
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')));
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [week, setWeek] = useState("Week 1");

    // const toggleSwitch = () => {
    //     setIsOn(!isOn);
    // };
    useEffect(() => {
        getAllTshirtOrdersForCurrentMonth();
    }, []);

    const getAllTshirtOrdersForCurrentMonth = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };
        axios.get(config.userUrl + 'user/getAllTshirtOrdersForLast28Days', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setOrders(response.data.orders);
                    setFilteredOrders(response.data.orders);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const classifyWeeks = () => {
        const endOfMonth = moment();
        const startOfMonth = moment().subtract(27, 'days'); 
        let currentStartDate = startOfMonth.clone();
        const weeks = [];

        // Loop through the last 28 days and divide it into weeks (7 days each)
        while (currentStartDate.isBefore(endOfMonth)) {
            let currentEndDate = currentStartDate.clone().add(6, 'days');
            if (currentEndDate.isAfter(endOfMonth)) {
                currentEndDate = endOfMonth.clone();
            }
            weeks.push({
                start: currentStartDate.format('YYYY-MM-DD'),
                end: currentEndDate.format('YYYY-MM-DD'),
                label: `Week ${weeks.length + 1}`
            });
            currentStartDate = currentEndDate.clone().add(1, 'day');
        }
        return weeks;
    };

    const filterOrdersByWeek = (week) => {
        const weeks = classifyWeeks();
        const selectedWeek = weeks.find(w => w.label === week);

        if (!selectedWeek) {
            setFilteredOrders(orders);
            return;
        }

        const filtered = orders.filter(order => {
            const orderDate = moment(order.createdAt);
            return orderDate.isBetween(selectedWeek.start, selectedWeek.end, 'days', '[]');
        });

        setFilteredOrders(filtered);
    };

    const onChangeWeek = (e) => {
        setWeek(e.target.value);
    };

    const toggleSwitch = () => {
        setIsOn(prevState => !prevState);
    };

    useEffect(() => {
        filterOrdersByWeek(week);
    }, [week, orders]);

    useEffect(() => {
        let filtered = [...orders];
        filtered = filtered.filter(order => {
            const orderDate = moment(order.createdAt);
            // Apply the week filter first
            const selectedWeek = classifyWeeks().find(w => w.label === week);
            if (selectedWeek && !orderDate.isBetween(selectedWeek.start, selectedWeek.end, 'days', '[]')) {
                return false; // Exclude orders not within the selected week
            }
            if (isOn) {
                return order.orderStatus === "Delivered";
            }
            return true;
        });
        setFilteredOrders(filtered);
    }, [isOn, orders, week]);
    return (
        <div>
            <div className="top_bar">
                <img src={loginlogo} className="login-logo" />
            </div>
            <div className="screen-topgap-a" style={{ fontFamily: "Noto Sans" }}>
                <div className="your-jitbits">Merchandise</div>
                <div className="bg-merchandise">
                    <div className="bg-white">
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "22%", fontSize: "14px" }} className="ml-3">{isOn ? "Delivered" : "All"}</div>
                            <div className={`toggle-container-admin ${isOn ? 'toggle-on-adm' : 'toggle-off-adm'}`} onClick={toggleSwitch}>
                                <div className="toggle-circle-admin"></div>
                            </div>
                            <div style={{ width: "26.5%" }}></div>
                            <div>
                                <select
                                    value={week}
                                    name="week"
                                    style={{ fontSize: "12px", fontFamily: "Noto Sans" }}
                                    onChange={onChangeWeek}
                                    className='form-control label-order-text'>
                                    {classifyWeeks().map((w) => (
                                        <option key={w.label} value={w.label}>{w.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="adm-bg-height">
                            {filteredOrders.map((data, i) => (
                                <Link to={`/orderstatus/${data.id}`} key={i}>
                                    <div className="ml-2 mr-2 adm-order-box">
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col xs={6}>
                                                <strong>{data.name}</strong>
                                                <div>{data.type}&nbsp;INR {data.totalCost}.00</div>
                                            </Col>
                                            <Col xs={3} style={{display: "flex"}}>
                                                <div className="mt-2" style={{ display: "flex" }}>
                                                    {/* <div
                                                        style={{
                                                            width: "10px",
                                                            height: "10px",
                                                            marginTop:"5px",
                                                            borderRadius: "50%",
                                                            background: data.transactionStatus == 1 ? "red" : "green",
                                                        }}
                                                    />&nbsp; */}
                                                    {data.orderId}
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div className="mt-2">{moment(data.createdAt).format("DD-MM-YYYY")} &nbsp;<i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-bottom"></div>
            <AdminMenu />
        </div>
    );
};

export default Merchandise;